import { isEmpty } from 'underscore';
import { t } from 'bv-i18n';
import apiSubmitSettings from 'MainSettings/api/submit_settings';
import depositLimitApi from 'DepositLimits/api';
import { loadCurrentLimits as loadCurrentDepositLimits } from 'DepositLimits/duck';
import { setFragment } from 'bv-helpers/location';
import * as api from './api';
import { getSettingsNamedValues } from './selectors';

export const FETCH_SETTINGS_INIT = 'saferGamblingPortal/FETCH_SETTINGS_INIT';
export const FETCH_SETTINGS_COMPLETE = 'saferGamblingPortal/FETCH_SETTINGS_COMPLETE';
export const UPDATE_SETTINGS_ENTRY = 'saferGamblingPortal/UPDATE_SETTINGS_ENTRY';
export const SUBMIT_SETTINGS_INIT = 'saferGamblingPortal/SUBMIT_SETTINGS_INIT';
export const SUBMIT_SETTINGS_SUCCESS = 'saferGamblingPortal/SUBMIT_SETTINGS_SUCCESS';
export const SUBMIT_SETTINGS_FAILURE = 'saferGamblingPortal/SUBMIT_SETTINGS_FAILURE';
export const FETCH_SECTIONS_INIT = 'saferGamblingPortal/FETCH_SECTIONS_INIT';
export const FETCH_SECTIONS_COMPLETE = 'saferGamblingPortal/FETCH_SECTIONS_COMPLETE';
export const UPDATE_REALITY_CHECK_FREQUENCY = 'saferGamblingPortal/UPDATE_REALITY_CHECK_FREQUENCY';
export const CLOSE_SUBMIT_RESULT_MODAL = 'saferGamblingPortal/CLOSE_SUBMIT_RESULT_MODAL';
export const TRIGGER_UPDATE = 'saferGamblingPortal/TRIGGER_UPDATE';

export const fetchSettingsInit = () => ({
  type: FETCH_SETTINGS_INIT,
});

export const fetchSettingsComplete = ({ settings, loadingStatusProp }) => ({
  type: FETCH_SETTINGS_COMPLETE,
  settings,
  loadingStatusProp,
});

export const submitSettingsInit = () => ({
  type: SUBMIT_SETTINGS_INIT,
});

export const submitSettingsSuccess = () => ({
  type: SUBMIT_SETTINGS_SUCCESS,
});

export const submitSettingsFailure = ({ message, generic }) => ({
  type: SUBMIT_SETTINGS_FAILURE,
  message,
  generic,
});

export const fetchSectionsInit = () => ({
  type: FETCH_SECTIONS_INIT,
});

export const fetchSectionsComplete = (sections) => ({
  type: FETCH_SECTIONS_COMPLETE,
  sections,
});

export const handleChange = ({ name, value }) => ({
  type: UPDATE_SETTINGS_ENTRY,
  name,
  value,
});

export const updateRealityCheckFrequency = (frequency) => ({
  type: UPDATE_REALITY_CHECK_FREQUENCY,
  frequency,
});

export const closeSubmitResultModal = () => ({
  type: CLOSE_SUBMIT_RESULT_MODAL,
});

export const triggerUpdate = ({ loadingStatusProp }) => ({
  type: TRIGGER_UPDATE,
  loadingStatusProp,
});

export const updateSectionEntry = ({ sectionName, key, newValue }) => (dispatch, getState) => {
  const { sections } = getState().saferGamblingPortal;
  dispatch(fetchSectionsInit());
  const newSections = sections.map(
    (section) => (section.name === sectionName ? { ...section, [key]: newValue } : section),
  );
  dispatch(fetchSectionsComplete(newSections));
};

export const fetchSettings = ({ settingsType, loadingStatusProp }) => (dispatch) => {
  dispatch(fetchSettingsInit());

  return api.fetchSettings(settingsType).then((settings) => {
    dispatch(fetchSettingsComplete({ settings, loadingStatusProp }));
  });
};

export const fetchSections = () => (dispatch) => {
  dispatch(fetchSectionsInit());

  return api.fetchSGPortalConfiguration().then(
    (response) => dispatch(fetchSectionsComplete(response)),
  );
};

export const submitDepositLimitsForm = (amount, period) => (dispatch, getState) => {
  dispatch(submitSettingsInit());

  const { limitOptions } = getState().depositLimits;

  return depositLimitApi[limitOptions.api].setNewLimit(amount, period)
    .then((response) => {
      if (response.success) {
        dispatch(submitSettingsSuccess());
        dispatch(loadCurrentDepositLimits());
      } else {
        let dispatchParams;
        const defaultErrorMessage = t('update_preferences_failed_message');

        if (isEmpty(response.errors)) {
          dispatchParams = { message: defaultErrorMessage, generic: true };
        } else {
          dispatchParams = { message: response.errors.deposit_limit, generic: false };
        }

        dispatch(submitSettingsFailure(dispatchParams));
      }
    });
};

export const handleSubmit = (event) => (dispatch, getState) => {
  event.preventDefault();
  dispatch(submitSettingsInit());

  const params = getSettingsNamedValues(getState());

  return apiSubmitSettings(params).then((response) => {
    if (response.success) {
      dispatch(submitSettingsSuccess());
      dispatch(updateRealityCheckFrequency(Number(response.reality_check_frequency_id)));
    } else {
      const defaultErrorMessage = t('update_preferences_failed_message');
      const dispatchParams = { message: defaultErrorMessage, generic: true };

      dispatch(submitSettingsFailure(dispatchParams));
    }
  });
};

export const accountClose = () => api.closeAccount()
  .then((response) => {
    if (response.success) {
      setFragment('/logout');
    }
  });

const initialState = {
  sections: [],
  sectionsLoaded: false,
  sectionsAreLoading: false,
  settings: {},
  realityChecksSettingsLoaded: false,
  gamblingSupportSettingsLoaded: false,
  settingsAreLoading: false,
  settingsAreSubmitting: false,
  settingsSubmitSucceed: false,
  settingsSubmitFailed: false,
  submissionError: {
    message: null,
    generic: null,
  },
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case FETCH_SETTINGS_INIT:
      return {
        ...state,
        settingsAreLoading: true,
      };
    case FETCH_SETTINGS_COMPLETE:
      return {
        ...state,
        settingsAreLoading: false,
        [action.loadingStatusProp]: true,
        settings: {
          ...state.settings,
          ...action.settings,
        },
      };
    case FETCH_SECTIONS_INIT:
      return {
        ...state,
        sectionsAreLoading: true,
      };
    case FETCH_SECTIONS_COMPLETE:
      return {
        ...state,
        sectionsAreLoading: false,
        sectionsLoaded: true,
        sections: action.sections,
      };
    case UPDATE_SETTINGS_ENTRY: {
      const [entryName, entryData] = Object.entries(state.settings)
        .find(([, value]) => (value ? value.name === action.name : null));

      return {
        ...state,
        settings: {
          ...state.settings,
          [entryName]: {
            ...entryData,
            value: Number(action.value) || action.value,
          },
        },
      };
    }
    case SUBMIT_SETTINGS_INIT:
      return {
        ...state,
        settingsAreSubmitting: true,
      };
    case SUBMIT_SETTINGS_SUCCESS:
      return {
        ...state,
        settingsAreSubmitting: false,
        settingsSubmitSucceed: true,
      };
    case SUBMIT_SETTINGS_FAILURE:
      return {
        ...state,
        settingsAreSubmitting: false,
        settingsSubmitFailed: true,
        submissionError: {
          message: action.message,
          generic: action.generic,
        },
      };
    case UPDATE_REALITY_CHECK_FREQUENCY:
      return {
        ...state,
        settings: {
          ...state.settings,
          realityChecks: {
            ...state.settings.realityChecks,
            value: action.frequency,
            initialValue: action.frequency,
          },
        },
      };
    case CLOSE_SUBMIT_RESULT_MODAL:
      return {
        ...state,
        settingsSubmitFailed: false,
        settingsSubmitSucceed: false,
        submissionError: initialState.submissionError,
      };
    case TRIGGER_UPDATE:
      return {
        ...state,
        [action.loadingStatusProp]: false,
      };
    default:
      return state;
  }
};
