import { getJSON, postJSON } from 'bv-fetch';

const getApi = (basePath) => ({
  fetchCurrentLimits: () => (
    getJSON(`/api/${basePath}/account_limits`)
  ),
  fetchLimitOptions: () => (
    getJSON(`/api/${basePath}/account_options`)
  ),
  setNewLimit: (amount, period) => (
    postJSON(`/api/${basePath}/account_limits`, { amount, period })
  ),
  confirmLimit: () => getJSON(`/api/${basePath}/account_limits/confirm`),
  cancelLimit: () => getJSON(`/api/${basePath}/account_limits/cancel`),
});

export default {
  rdl: getApi('deposit_limits'),
  ndl: getApi('net_deposit_limit'),
};
