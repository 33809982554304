import { setEnabled as setLimitHistoryEnabled } from 'LimitHistory/duck';
import { limitType } from 'LimitHistory/helpers';
import api from './api';

const SET_CURRENT_LIMITS = 'depositLimits/SET_CURRENT_LIMITS';
const SET_LIMIT_OPTIONS = 'depositLimits/SET_LIMIT_OPTIONS';

const setCurrentLimits = (payload) => ({
  type: SET_CURRENT_LIMITS,
  payload,
});

const setLimitOptions = (payload) => ({
  type: SET_LIMIT_OPTIONS,
  payload,
});

export const loadCurrentLimits = () => (dispatch, getState) => {
  const { limitOptions } = getState().depositLimits || {};
  if (!limitOptions?.api) return;

  api[limitOptions.api].fetchCurrentLimits()
    .then((response) => dispatch(setCurrentLimits(response)));
};

export const loadLimitOptions = () => (dispatch) => (
  api.ndl.fetchLimitOptions()
    .then((response) => dispatch(setLimitOptions({ ...response, api: 'ndl' })))
    .catch(() => {
      api.rdl.fetchLimitOptions()
        .then((response) => {
          const { limitHistoryEnabled, ...options } = response;
          dispatch(setLimitOptions({ ...options, api: 'rdl' }));
          dispatch(setLimitHistoryEnabled(limitHistoryEnabled || false, limitType.DEPOSIT_LIMITS));
        });
    })
);

export const initialState = {
  currentLimits: null,
  limitOptions: null,
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_CURRENT_LIMITS:
      return {
        ...state,
        currentLimits: action.payload,
      };
    case SET_LIMIT_OPTIONS:
      return {
        ...state,
        limitOptions: action.payload,
      };
    default:
      return state;
  }
};
